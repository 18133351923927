const base ={
    getMeal:"api/quartz/mealChange/mealChangeLiat",
    surCostEdit:"api/quartz/mealChange/surCostEdit",
    survey:"api/quartz/mealChange/survey",
    uploadPdf:"api/quartz/mealChange/uploadPdf",
    getCsuId:"api/quartz/mealChange/getCsuId",


}

export default base
