import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'
//encapsulation http
import http from '@/api/http'
import '@/assets/css/reset.css'
//survey.js
import { surveyPlugin } from 'survey-vue3-ui'
const app =createApp(App)
const pinia =createPinia()
// Mount Global
app.config.globalProperties.$axios=http
app.use(pinia).use(router).use(surveyPlugin).use(ElementPlus).mount('#app')
